import * as React from 'react';

import { AppPageLayout, AppPageLinks } from 'src/app.consumer/components';
import { Localized, SearchResults } from 'src/app/components';
import { AppProps, SeoObject } from 'src/app/types';

const seo: SeoObject = {
  title: 'Explore search results',
};

// TODO: still needed?
// TODO: i18n
/** @deprecated */
const ExplorePage = (props: AppProps) => {
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={'Explore search results'}
      title={<Localized dictKey={'staticLocales:pages./explore.link label'} />}
      {...{ ...props, seo }}
    >
      {/* // TODO:  */}
      {/* <SearchResults /> */}
    </AppPageLayout>
  );
};
/** @deprecated */
export default ExplorePage;
